import React from "react"
import { AddressListType } from "../approve-info-view/type"

export default function ShopAddressItem(props: AddressListType) {
  const {
    name,
    country,
    district,
    province,
    street,
    sub_street,
    ward,
    country_code,
    is_default,
  } = props
  return (
    <div className="flex w-full flex-col gap-2 border border-gray-300 rounded-3 p-1">
      {/* Address name */}
      <div className="flex w-full flex-col">
        <span className="w-full text-14 font-bold leading-20 text-gray-700">
          {is_default ? "Primary address name" : "Name"}
        </span>
        <input
          className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
          placeholder="Empty"
          name="shop_name"
          readOnly
          value={name}
        />
      </div>
      {/* Country */}
      <div
        id="shopInformation"
        className="flex flex-col items-center gap-3 md:flex-row"
      >
        {/* City */}
        <div className="flex w-full flex-1 flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {"Country"}
          </span>
          <input
            value={country?.name}
            name="shop_country"
            className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
            placeholder="Empty"
            readOnly
          />
        </div>
        {/* State */}
        <div className="flex w-full flex-1 flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {country_code !== "vn" ? "State/Province" : "City/Province"}
          </span>
          <input
            value={province?.name}
            name="shop_country"
            className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
            placeholder="Empty"
            readOnly
          />
        </div>
      </div>
      <div
        id="shopInformation"
        className="flex flex-col items-center gap-3 md:flex-row"
      >
        {/* Country */}
        <div className="flex w-full flex-1 flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {country_code !== "vn" ? "City" : "District"}
          </span>
          <input
            value={district?.name}
            name="shop_country"
            className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
            placeholder="Empty"
            readOnly={true}
          />
        </div>
        <div className="flex w-full flex-1 flex-col">
          <span className="text-14 font-medium leading-20 text-gray-700">
            {"Ward"}
          </span>
          <input
            value={ward?.name}
            className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
            placeholder="Empty"
            name="shop_ward"
            readOnly
          />
        </div>
      </div>
      {/* Address 1 */}
      <div className="flex w-full flex-col">
        <span className="text-14 font-medium leading-20 text-gray-700">
          {country?.code === "vn" ? "Address line" : "Address line 1"}
        </span>
        <input
          className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
          placeholder="Empty"
          name="shop_name"
          readOnly
          value={street}
        />
      </div>
      {/* Address 2 */}
      {country?.code != "vn" ? (
        <>
          <div className="flex w-full flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {"Address line 2"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={sub_street}
            />
          </div>
        </>
      ) : null}
    </div>
  )
}
