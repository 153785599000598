
import { Dialog } from "primereact/dialog"
import React from "react"
import ImgCustom from "src/components/img-custom"
import VideoCustom from "../rating-product-modal/video-custom"
import "./index.scss"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { MediaType } from "src/pages/shopping/product-detail/props"
interface MediaModalProps {
  handleClose: () => void;
  mediaActivated: {
    id: string;
    index: number;
  }
  listImage: MediaType[]
}

export default function MediaModal(props: MediaModalProps) {
  const { handleClose, listImage, mediaActivated } = props

  const preventSelection = (e: React.MouseEvent) => {
    e.preventDefault()
  }

  return (
    <Dialog id="video_full_screen" visible={true} style={{ width: "650px" }} onHide={handleClose}>
      <Swiper
        navigation={true}
        spaceBetween={38}
        modules={[Navigation]}
        slidesPerView={1}
        initialSlide={mediaActivated.index}
      >
        {listImage?.map((image) => (
          <SwiperSlide key={image.id} className={image?.isImage ? "swiper-slide" : ""} onMouseDown={image?.isImage ? preventSelection : undefined}>
            {image?.isImage ? (
              <div className="h-full w-full cursor-pointer focus:shadow-none ">
                <ImgCustom
                  url={image?.id}
                  alt="commentImage"
                  className="!h-[650px] w-[650px] rounded-4 object-contain p-6px"
                />
              </div>
            ) : (
              <div className=" !h-[650px] w-[650px] cursor-pointer object-contain">
                <VideoCustom type={image?.type} idSource={image?.id} />
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </Dialog>
  )
}