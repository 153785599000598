import React, { useEffect, useState } from "react"
import { ShopDataProps } from "../approve-info-view/type"
import { CurrencyType } from "../request-open-view/types"
import { getCurrencies } from "src/services/billing-service"
import ShopAddressItem from "./item-view"

export default function ApproveShopInformation(props: ShopDataProps) {
  const { name, currency, address_list } = props

  const [currencyData, setCurrencyData] = useState<CurrencyType[]>([])
  const [currentCurrencyData, setCurrentCurrencyData] = useState<string>("")

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await getCurrencies()
        setCurrencyData(res?.data?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])
  useEffect(() => {
    if (currencyData && currency) {
      const currencyExist = currencyData.find((currencyItem) => currencyItem.code === currency)
      setCurrentCurrencyData(`${currencyExist?.code} - ${currencyExist?.name}`)
    } else {
      setCurrentCurrencyData("Empty")
    }
  }, [currencyData, currency])

  return (
    <div className="w-full p-3">
      <div className="flex w-full flex-col lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {"Shop information"}
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2 lg:w-2/3">
          {/* Shop name */}
          <div className="flex w-full flex-col">
            <span className="w-full text-14 font-medium leading-20 text-gray-700">
              {"Merchant name"}
            </span>
            <input
              className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
              placeholder="Empty"
              name="shop_name"
              readOnly
              value={name}
            />
          </div>
          {/* LIST ADDRESS */}
          <div className="flex flex-col gap-1 w-full">
            {
              address_list?.map(i => (
                <div key={i?.id || ""}>
                  <ShopAddressItem {...i}/>
                </div>
              ))
            }
          </div>
          {/* Currency */}
          <div id="shopInformation" className="flex flex-col items-center gap-3 md:flex-row">
            <div className="flex w-full flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">Currency</span>
              <input
                value={currentCurrencyData}
                name="shop_country"
                className="h-[44px] w-full rounded-3 border border-gray-300 px-2 text-14 font-normal leading-5 text-gray-900 focus:outline-none"
                placeholder="Empty"
                readOnly={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
