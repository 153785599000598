import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useToast } from "src/hooks/use-toast"

export interface ImageType {
  url: string
  file: File | null
}

interface UploadImageProps {
  title: string
  image: ImageType
  setImage: Dispatch<SetStateAction<ImageType>>
}

export default function UploadImage({ title, image, setImage }: UploadImageProps) {
  const { t } = useTranslation()
  const imageRef = useRef<HTMLInputElement>(null)
  const showToast = useToast()

  const onSelectImage = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      handleUploadImages(files)
    }
  }

  const handleUploadImages = (files: FileList) => {
    const allowedFormats = ["image/png", "image/jpg", "image/jpeg", "image/webp"]

    if (allowedFormats.includes(files[0].type)) {
      const url = URL.createObjectURL(files[0])
      setImage({
        url,
        file: files[0],
      })
    } else {
      showToast({
        detail: t("request-open-page.wrong-file-format"),
        severity: "error",
      })
    }
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    const files = event.dataTransfer?.files
    if (files) {
      handleUploadImages(files)
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const clickUpload = () => {
    if (imageRef.current) {
      imageRef.current?.click()
    }
  }

  return (
    <>
      {/* FRONT */}
      <input
        className="hidden"
        accept="image/*"
        ref={imageRef}
        type="file"
        onClick={() => {
          if (imageRef.current) {
            imageRef.current.value = ""
          }
        }}
        onChange={(e) => onSelectImage(e)}
      />
      <div
        className="flex-1 px-3 pt-2 bg-gray-100 rounded-3">
        <h3 className="font-medium text-14 text-center mb-2">{title}</h3>
        <div className="h-[220px]">
          {image.url && <img src={image.url} alt="" className="h-full w-full object-cover" />}
          {!image.url && <div
            className="flex flex-col items-center justify-center rounded-3 bg-white h-full"
            onDrop={(e) => onDrop(e)}
            onDragOver={(e) => onDragOver(e)}
            onDragLeave={(e) => onDragLeave(e)}
          >
            <div className="mb-2 h-32px w-32px flex items-center justify-center rounded-circle bg-gray-100">
              <i className="sctr-icon-upload-cloud-01"></i>
            </div>
            <div className="mb-4px">
              <span className="text-14 text-blue-600 font-medium mr-4px cursor-pointer" onClick={clickUpload}>
                {t("request-open-page.click-to-upload")}
              </span>
              <span className="text-14">{t("request-open-page.or-drag-drop")}</span>
            </div>
            <p className="text-12 text-gray-500">{t("request-open-page.accept-image-id")}</p>
          </div>}
        </div>
        <div className="h-[52px] flex items-center gap-1 justify-center">
          {image.url && <>
            <button className="bg-white rounded-3 border py-4px px-5 font-semibold text-14" onClick={clickUpload}>
              {t("request-open-page.upload")}
            </button>
            <button onClick={() => setImage({
              url: "",
              file: null,
            })} className="bg-white rounded-3 border py-4px px-5 font-semibold text-14">
              {t("request-open-page.remove")}
            </button>
          </>}
        </div>
      </div>
    </>
  )
}