/* eslint-disable react/prop-types */
import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import CustomCard from "../card-custom/card-custom/custom-card"
import { CardCustomType } from "../../constants/constants/card-custom-type"
import "swiper/css"
import "swiper/css/navigation"
import "./index.scss"
import { handleClickSponsors } from "src/services/sponsor-service"
import { DtoClickSponsor } from "./type"
import { CustomCardProps } from "../card-custom/card-custom/custom-card-props"

export interface SponsorViewType {
  bloom_filter: string
  space: number
  classCard?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sponsorData: any[]
  handleReload: () => void
}

export default function HomePageSponsor(props: SponsorViewType) {
  const { bloom_filter, space, classCard, handleReload, sponsorData } =
    props

  const handleClickSponsor = async (id: string) => {
    const dto = {
      platform: "Web",
      screenSize: "1920x1080",
      dataArray: [
        {
          m: "Ecommerce",
          c: "EA",
          a: "C",
          d: id,
          co: 1,
          l: "Ecommerce click event",
        },
      ],
    }

    await handleClickSponsors(dto as DtoClickSponsor)
      .then(() => {
        console.log("Click successfully")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const detectStructView = (index: number) => {
    const startIndex = Math.floor(index/5) * 5
    const endIndex = startIndex + 5
    const dataDetect = sponsorData.slice(startIndex, endIndex)
    let isSold = false
    let isFlashDeal = false
    let isVoucherShop = false
    dataDetect.forEach(i => {
      if((i?.sold || 0) > 0) isSold = true
      if(i?.flash_deal_status === "ONGOGING" || i?.flash_deal_status === "UPCOMING" ) isFlashDeal = true
      if(i?.vouchers?.length) isVoucherShop = true
    })
    const data = {
      isFlashView: isFlashDeal,
      isSoldView: isSold,
      isShopVoucher: isVoucherShop,
    }
    return data
  }

  return (
    <>
      {sponsorData?.length ? (
        <div className="mb-4px flex w-full items-center">
          <div className="w-full">
            <div
              id="home-page-sponsor"
              className="relative flex h-full w-full justify-center gap-1"
            >
              <Swiper
                navigation={true}
                spaceBetween={space}
                modules={[Navigation]}
                slidesPerView={"auto"}
              >
                {sponsorData
                  ?.slice(0, 5)
                  ?.map((pro: CustomCardProps, index) => (
                    <SwiperSlide key={index}>
                      <div
                        onClick={() => handleClickSponsor(pro?.id || "")}
                        className={` ${
                          classCard
                            ? classCard
                            : "w-[175px] sm:w-[228.5px] md:w-[241.33px] lg:w-[229.75px] xl:w-[217px] h-full"
                        }`}
                      >
                        <CustomCard
                          key={pro?.id}
                          id={pro?.id}
                          typeCard={CardCustomType.product}
                          imgUrl={pro?.avatar?.id}
                          avatar={pro?.avatar}
                          name={pro?.name}
                          point={pro?.point}
                          sum_review={pro?.sum_review}
                          price={pro?.price}
                          price_after_tax={pro?.price_after_tax}
                          original_price={pro?.original_price}
                          original_price_after_tax={pro?.original_price_after_tax}
                          quantity={pro?.quantity}
                          productAttr={pro?.product_attributes}
                          productId={pro?.id}
                          shop_id={pro?.shop_id}
                          shop_name={pro?.shop?.name}
                          sub_label={pro?.sub_label}
                          shop={pro?.shop}
                          sold={pro?.sold}
                          voucherOfProducts={[pro?.voucher || ""]}
                          bloom_filter={bloom_filter}
                          handleReload={handleReload}
                          is_flash_deal={pro?.is_flash_deal}
                          flash_deal_status={pro?.flash_deal_status}
                          flash_deals={pro?.flash_deals}
                          flash_deals_time={pro?.flash_deals_time}
                          vouchers={pro?.vouchers}
                          structView={detectStructView(index)}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
