import React, { useState, useMemo, useEffect } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { useTranslation } from "react-i18next"
import { EditProductModal, ConfirmModal } from "src/components/modal"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import { Checkbox } from "primereact/checkbox"
import "./index.scss"
import ImgCustom from "../../img-custom"
import { Link, useNavigate } from "react-router-dom"
import { ObjCartType, ObjectDtoType } from "../type"
import { FLASH_DEAL_TYPE, LOGO, SERVICE, TYPELINK } from "src/constants/common"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { configHeader } from "src/config/interceptors"
import { useSelector } from "react-redux"
import { RootState } from "src/app/store"
import { convertViToEn, getFlashDealTime, mathRoundNumber } from "src/utils/common"
import flashDealIcon from "src/assets/images/flash-deal-icon.svg"
import CountDownCartView from "./count-down-cart"
import { TimeListType } from "src/features/system/props"
import liveStreamIcon from "src/assets/images/live-stream-icon.svg"
import InputNumberCart from "src/components/input-number-cart"
import { PRODUCT_TYPE } from "src/components/input-number-cart/types"
import { convertCurrency } from "src/utils/convert-currency"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"

const ProductCartView = (props: ObjCartType) => {
  const { objectDto, updateProductShop, cartId, deleteProduct, is_suspension_mode, id} = props
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowEditModal, setIsShowEditModal] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [productDetail, setProductDetail] = useState<any>()
  const [productConfirm, setProductConfirm] = useState<string>("")
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const systemStore = useSelector((state: RootState) => state.system)
  const navigator = useNavigate()

  const timeFlashDeal = () => {
    let result: undefined | TimeListType = undefined
    if (systemStore.value.length) {
      result = getFlashDealTime(systemStore.value)
    }
    return result
  }

  const ProductTemplate = (rowData: ObjectDtoType) => {
    const {
      title,
      isSelected,
      objectImgUrl,
      stockPriceFull,
      id,
      objectId,
      stockDetail,
      quantity,
      isFlashDeal,
      isLiveStream,
      is_market_public,
      flashDeal,
    } = rowData
    const updateProductSelected = (checkValue: boolean) => {
      if (updateProductShop) {
        updateProductShop({ ...rowData, isSelected: checkValue })
      }
      const itemData = {
        item_id: id,
        item_name: title,
        price: mathRoundNumber(stockDetail.price_after_tax),
        quantity: quantity,
      }
      const gaData = {
        item_list_id: "MY_CART",
        item_list_name: `SELECT_ITEM_SHOP_${cartId}`,
        items: itemData,
      }
      gaPostAction(TAG_TYPE.VIEW_ITEM_LIST, gaData)
    }

    const handleOpenProductDetail = () => {
      const isLiveStreamDeal = rowData.isLiveStream && rowData.liveStreamDeal && rowData.liveStreamDeal?.quantity > 0
      setProductDetail({
        stock: stockDetail,
        stockPriceFull: stockPriceFull,
        productId: id,
        quantity: quantity,
        isLiveStreamDeal: isLiveStreamDeal,
        stockQuantity: getStockQuantity,
      })
      setIsShowEditModal(true)
    }

    const goToProduct = () => {
      const productCode = objectId?.split("-")?.length ? objectId?.split("-")[0]: ""
      navigator(`/shopping/${convertViToEn(title)}/${objectId}/${productCode}`)
    }
    const getVariation = useMemo(() => {
      return stockDetail?.variation_second_name
        ? `${stockDetail.variation_first_name}/${stockDetail.variation_second_name}`
        : stockDetail.variation_first_name
    }, [stockDetail])
    const getStockQuantity = useMemo(() => {
      let result = 0
      if (
        rowData.liveToken &&
        rowData.isLiveStream &&
        rowData.liveStreamDeal &&
        quantity <=
          rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      ) {
        result = rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      } else if (
        isFlashDeal &&
        rowData.flashDeal &&
        timeFlashDeal() &&
        quantity <= rowData.flashDeal.max_sold - rowData.flashDeal.sold
      ) {
        result = rowData.flashDeal.max_sold - rowData.flashDeal.sold
      } else {
        result = stockDetail?.quantity
      }
      return result
    }, [stockDetail, quantity])

    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <div>
            <Checkbox
              className="h-[22px] w-[22px]"
              onChange={(e) => updateProductSelected(e.checked as boolean)}
              checked={isSelected as boolean}
              disabled={is_suspension_mode || !is_market_public}
            />
          </div>
          <div className="ml-1 h-[66px] min-h-[66px] w-[66px] min-w-[66px] rounded-2 border border-gray-200">
            {objectImgUrl?.includes(TYPELINK.DOMAIN_URL_YOUTUBE) ? (
              <img
                src={`https://img.youtube.com/vi/${
                  objectImgUrl?.split("/").slice(-1)[0]
                }/maxresdefault.jpg`}
                alt="img-video"
                className="h-full w-full object-cover"
              />
            ) : (
              <ImgCustom
                className="h-full w-full object-cover"
                alt="objImg"
                url={objectImgUrl as string}
              />
            )}
          </div>
          <div className="ml-1 flex flex-col">
            <span
              onClick={goToProduct}
              className="cursor-pointer text-12 font-semibold leading-18 text-gray-700 line-clamp-2 hover:text-blue-700 hover:underline"
            >
              {title}
            </span>
            <div className="mt-2px flex items-center gap-[28px]">
              {stockDetail?.quantity ? (
                <span className="truncate text-10 font-medium leading-16 text-gray-500">
                  {capitalizedFirst(
                    t("shoppingPage.stocks_remaining", {
                      count: getStockQuantity || 0,
                    })
                  )}
                </span>
              ) : null}
              {rowData.isLiveStream && rowData.liveStreamDeal?.live_id && rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold >= quantity ? (
                <Link
                  to={`${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/livestream/room/${rowData.liveStreamDeal.live_id}`}
                  className="flex items-center gap-4px rounded-[4px] bg-[linear-gradient(263.89deg,#FF692E_0%,#FF4405_100%)] px-4px py-2px text-[10px] font-semibold leading-[16px] text-white hover:opacity-75"
                  target="_blank"
                >
                  <img
                    alt="liveStreamIcon"
                    src={liveStreamIcon}
                    className="h-[12px] w-[12px]"
                  />
                  <span>{capitalizedFirst(t("shoppingCartPage.still-in-live"))}</span>
                </Link>
              ) : null}
            </div>
            {stockPriceFull?.length ? (
              <div className="item-center mt-2px flex text-12 font-normal leading-18 text-gray-600">
                <span>{capitalizedFirst(t("shoppingCartPage.variation"))}</span>
                <div
                  className="item-center ml-4px flex cursor-pointer"
                  onClick={handleOpenProductDetail}
                >
                  <span className="font-semibold">{getVariation}</span>
                  <i className="pi pi-chevron-down ml-1" />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {isFlashDeal && timeFlashDeal() && !isLiveStream  && ((flashDeal?.max_sold || 0) - (flashDeal?.sold || 0) > 0) ? (
          <div className="mt-1 ml-4 flex items-center">
            <img src={flashDealIcon} alt="flashDealIcon" />
            <span className="ml-4px text-[12px] font-semibold leading-[16px] text-orange-dark-400">
              {`${LOGO.NAME} flash deals`}
            </span>
            <div className="ml-4px flex items-center">
              <CountDownCartView target={timeFlashDeal()?.timeStampEnd || 0} />
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  const PriceTemplate = (rowData: ObjectDtoType) => {
    const {
      stockDetail,
      isFlashDeal,
      flashDeal,
      liveToken,
      isLiveStream,
      liveStreamDeal,
      quantity,
    } = rowData

    const getPrice = useMemo(() => {
      let result = 0
      if (
        liveToken &&
        isLiveStream &&
        liveStreamDeal &&
        quantity <= liveStreamDeal.quantity - liveStreamDeal.sold
      ) {
        result = (100 - liveStreamDeal.discount_percent) * stockDetail.original_price_after_tax / 100
      } else if (
        isFlashDeal &&
        flashDeal &&
        timeFlashDeal() &&
        quantity <= flashDeal.max_sold - flashDeal.sold
      ) {
        if (flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
          result = flashDeal.marketing_price
        } else {
          result = (100 - flashDeal.marketing_price) * stockDetail.original_price_after_tax / 100
        }
      } else {
        result = stockDetail?.price_after_tax
      }
      
      return result
    }, [stockDetail, quantity, flashDeal, systemStore.value])
    return (
      <div className="ml-4 md:ml-0 flex flex-row md:flex-col font-medium gap-1">
        <span className="text-14 leading-20 text-gray-900">
          {convertCurrency(getPrice)}
        </span>
        {(getPrice !== stockDetail?.original_price_after_tax) ? 
          <span className="text-12 leading-18 line-through">
            {convertCurrency(stockDetail?.original_price_after_tax)}
          </span> : null }
      </div>
    )
  }

  const QuantityTemplate = (rowData: ObjectDtoType) => {
    const { quantity, id, objectId, stockDetail, entityId, isFlashDeal, limit_per_item, catalogs } =
      rowData
    const updateCartQuantity = (newQuantity: number) => {
      if (updateProductShop) {
        if (authStore.value) {
          new CartControllerApi(configHeader(SERVICE.CART))
            .cartsIdPut(
              {
                object_type: "PRODUCT",
                entity_id: entityId,
                object_id: objectId,
                stock_id: stockDetail.id,
                quantity: newQuantity,
              },
              id
            )
            .then(() => {
              updateProductShop({ ...rowData, quantity: newQuantity })
            })
        } else updateProductShop({ ...rowData, quantity: newQuantity })
      }
    }
    const [type, setType] = useState<string>(PRODUCT_TYPE.ORIGIN)
    const confirmDelete = () => {
      setProductConfirm(id)
      setIsShowConfirmModal(true)
    }
    const getTypeMax = useMemo(() => {
      let result = 0
      if (rowData.liveToken && rowData.isLiveStream && rowData.liveStreamDeal) {
        result = rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      } else if (isFlashDeal && rowData.flashDeal && timeFlashDeal()) {
        result = rowData.flashDeal.max_sold - rowData.flashDeal.sold
      }
      return result
    }, [])
    const getTypeChange = useMemo(() => {
      let result = ""
      if (rowData.liveToken && rowData.isLiveStream && rowData.liveStreamDeal) {
        result = PRODUCT_TYPE.LIVE_STREAM
      } else if (isFlashDeal && rowData.flashDeal && timeFlashDeal()) {
        result = PRODUCT_TYPE.FLASH_DEAL
      }
      return result
    }, [])
    useEffect(() => {
      let result = ""
      if (
        rowData.liveToken &&
        rowData.isLiveStream &&
        rowData.liveStreamDeal &&
        quantity <=
          rowData.liveStreamDeal.quantity - rowData.liveStreamDeal.sold
      ) {
        result = PRODUCT_TYPE.LIVE_STREAM
      } else if (
        isFlashDeal &&
        rowData.flashDeal &&
        timeFlashDeal() &&
        quantity <= rowData.flashDeal.max_sold - rowData.flashDeal.sold
      ) {
        result = PRODUCT_TYPE.FLASH_DEAL
      }
      if (result) setType(result)
    }, [])
    const isTicket = useMemo(() => {
      let result = false
      catalogs?.forEach(i => {
        if(i.type === "E_TICKET") result = true
      })
      return result
    }, [])
    return (
      <div className="ml-4 md:ml-0  flex w-[100px] max-w-[120px] items-center justify-between">
        <InputNumberCart
          confirmDelete={confirmDelete}
          value={quantity}
          updateValue={updateCartQuantity}
          max={isTicket && limit_per_item > 0 ? limit_per_item  : stockDetail?.quantity}
          typeMax={getTypeMax}
          confirmChangeType={(newType: string) => setType(newType)}
          type={type}
          typeChange={getTypeChange}
          stockId={stockDetail?.id}
        />
      </div>
    )
  }

  const TotalPriceTemplate = (rowData: ObjectDtoType) => {
    const {
      stockDetail,
      quantity,
      isFlashDeal,
      flashDeal,
      liveToken,
      isLiveStream,
      liveStreamDeal,
      // tax,
    } = rowData

    const getPrice = () => {
      let result = 0
      if (
        liveToken &&
        isLiveStream &&
        liveStreamDeal &&
        quantity <= liveStreamDeal.quantity - liveStreamDeal.sold
      ) {
        result = (100 - liveStreamDeal.discount_percent) * stockDetail.original_price_after_tax / 100
      } else if (
        isFlashDeal &&
        flashDeal &&
        timeFlashDeal() &&
        quantity <= flashDeal.max_sold - flashDeal.sold
      ) {
        if (flashDeal.type === FLASH_DEAL_TYPE.FIXED_PRICE) {
          result = flashDeal.marketing_price
        } else {
          result = (100 - flashDeal.marketing_price) * stockDetail.original_price_after_tax / 100
        }
      } else {
        result = stockDetail?.price_after_tax
      }

      return result
    }

    const totalPrice = useMemo(() => {
      return getPrice() * quantity
    }, [quantity, stockDetail])
    return (
      <div className="flex flex-col gap-1">
        <span className="text-[14px] leading-20 text-gray-700 font-semibold">
          {convertCurrency(totalPrice)}
        </span>
      </div>
    )
  }
  const DeleteItemTemplate = (rowData: ObjectDtoType) => {
    const deleteItem = () => {
      setProductConfirm(rowData.id)
      setIsShowConfirmModal(true)
    }
    return (
      <span className="font-14 font-medium leading-20 text-gray-700">
        <i onClick={deleteItem} className="text-red-500 sctr-icon-trash-03 text-[20px] cursor-pointer"/>
      </span>
    )
  }
  const tableFields = [
    {
      field: "product",
      label: "Product",
      customStyle: {},
      isCustom: true,
      customClass: "",
      template: ProductTemplate,
    },
    {
      field: "price",
      label: "Unit Price",
      customStyle: {
        width: "100px",
      },
      customClass: "",
      template: PriceTemplate,
    },
    {
      field: "quantity",
      label: "Quantity",
      customStyle: {
        width: "110px",
      },
      customClass: "",
      template: QuantityTemplate,
    },
    {
      field: "totalPrice",
      label: "Total Price",
      customStyle: {
        width: "100px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      customClass: "px-[0px]",
      template: TotalPriceTemplate,
    },
    {
      field: "deleteItem",
      label: "Delete",
      customStyle: {
        width: "50px",
      },
      customClass: "",
      template: DeleteItemTemplate,
    },
  ]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleEditProductStock = (updateProduct: any) => {
    const currentProduct = objectDto.find(
      (p) => p.id === updateProduct?.productId
    )
    if (updateProductShop && currentProduct) {
      new CartControllerApi(configHeader(SERVICE.CART))
        .cartsIdPut(
          {
            object_type: "PRODUCT",
            entity_id: currentProduct?.entityId,
            object_id: currentProduct?.objectId,
            stock_id: updateProduct?.stock?.id,
            quantity: updateProduct?.quantity,
          },
          currentProduct?.id
        )
        .then(() => {
          updateProductShop({
            ...currentProduct,
            quantity: updateProduct?.quantity,
            stockDetail: updateProduct.stock,
          })
          setIsShowEditModal(false)
        })
    }
  }

  const handleDeleteProduct = () => {
    const callback = () => {
      setIsShowConfirmModal(false)
    }
    if (deleteProduct) {
      deleteProduct({ id, cartId, productConfirm, callback })
    }
  }
  const shopProducts = useMemo(() => {
    const result: ObjectDtoType[] = []
    objectDto.forEach((prod) => {
      if (prod.isDisplay) result.push(prod)
    })
    return result
  }, [objectDto])

  return (
    <div className="shopping-product-table -[100%] w-full" id="customTable">
      <div className="hidden md:block">
        <DataTable value={shopProducts} dataKey="id">
          {tableFields.map((item, index) => (
            <Column
              key={index}
              field={item.field}
              bodyStyle={item.customStyle}
              body={item.template}
              className={item.customClass}
            />
          ))}
        </DataTable>
      </div>
      <div className="block md:hidden">
        {shopProducts.map(i => (
          <>
            <div className="flex flex-col p-3 gap-1">
              <ProductTemplate {...i}/>
              <PriceTemplate {...i}/>
              <QuantityTemplate {...i}/>
            </div>
          </>
        ))}
      </div>
      {isShowConfirmModal && (
        <ConfirmModal
          isShow={isShowConfirmModal}
          title={capitalizedFirst(t("modal.confirmModal"))}
          message={capitalizedFirst(t("modal.confirmModalMessage"))}
          confirmLabel={"global.delete"}
          handleClose={() => setIsShowConfirmModal(false)}
          handleConfirm={handleDeleteProduct}
        />
      )}
      {isShowEditModal && (
        <EditProductModal
          id={id}
          stock={productDetail?.stock}
          shopId={cartId}
          quantityCurrent={productDetail?.quantity}
          isShow={isShowEditModal}
          stockQuantity={productDetail?.stockQuantity}
          isLiveStreamDeal={productDetail?.isLiveStreamDeal}
          data={productDetail?.stockPriceFull}
          productId={productDetail?.productId}
          handleClose={() => setIsShowEditModal(false)}
          handleConfirm={handleEditProductStock}
        />
      )}
    </div>
  )
}

export default ProductCartView
