import React, { useEffect, useMemo, useState } from "react"
import { Dialog } from "primereact/dialog"
import { EditModalProps } from "./type"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { StockView } from "./stock-view"
import { StockControllerApi } from "@soctrip/axios-stock-service"
import { configHeader } from "src/config/interceptors"
import InputNumberCustom from "src/components/input-number"
import { SERVICE } from "src/constants/common"
import { RootState } from "src/app/store"
import { useSelector } from "react-redux"

export default function EditProductModal(props: EditModalProps) {
  const { t } = useTranslation()
  const {
    isShow,
    handleClose,
    handleConfirm,
    stock,
    data,
    shopId,
    productId,
    quantityCurrent,
    stockQuantity,
    isLiveStreamDeal,
    id,
  } = props
  const [quantity, setQuantity] = useState<number>(1)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [stockValue, setStockValue] = useState<any>()
  const [variationFirst, setVariationFirst] = useState<string>("")
  const [variationSecond, setVariationSecond] = useState<string>("")
  const [isOutOfStock, setIsOutOfStock] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [firstData, setFirstData] = useState<any | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [secondData, setSecondData] = useState<any | undefined>(undefined)
  const shoppingCartStore = useSelector(
    (state: RootState) => state.shoppingCart
  )
  const ticketData = useMemo(() => {
    const result = {
      isTicket: false,
      maxLimit: 0,
    }
    const currentShop = shoppingCartStore.cartData.find(i => i?.id === id)
    if(currentShop) {
      const proCurrent =currentShop.objectDto.find(pro => pro.id === productId)
      if(proCurrent && proCurrent.catalogs?.length && proCurrent.catalogs[0].type === "E_TICKET") {
        result.isTicket = true
        result.maxLimit = proCurrent.limit_per_item
      }
    }
    return result
  }, [])

  const getStock = (firstId: string, secondId: string) => {
    new StockControllerApi(configHeader(SERVICE.STOCK))
      .stocksVariationsOriginalGet(firstId, secondId || undefined)
      .then((res) => {
        if (res?.data?.data) {
          setVariationFirst(firstId)
          setVariationSecond(secondId)
          setStockValue(res.data.data)
        } else {
          setIsOutOfStock(true)
        }
      })
      .catch(() => {
        setIsOutOfStock(true)
      })
  }
 
  const handleUpdateVariationFist = (newValue: string) => {
    getStock(newValue, variationSecond)
    setQuantity(1)
  }
  const handleUpdateVariationSecond = (newValue: string) => {
    getStock(variationFirst, newValue)
    setQuantity(1)
  }

  const handleSave = () => {
    if (isOutOfStock) handleClose()
    else {
      handleConfirm({
        shopId: shopId,
        stock: stockValue,
        productId: productId,
        quantity: quantity,
      })
    }
  }

  const getStockQuantity = () => {
    return isLiveStreamDeal ? stockQuantity : stockValue?.quantity
  }

  useEffect(() => {
    if (stock) {
      if (stock?.variation_first_id) {
        setVariationFirst(stock?.variation_first_id)
      }
      if (stock?.variation_second_id) {
        setVariationSecond(stock?.variation_second_id)
      }
      getStock(
        stock?.variation_first_id as string,
        stock?.variation_second_id as string
      )
    }
  }, [stock])

  useEffect(() => {
    if (data?.length) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data.forEach((i: any) => {
        if (i?.order === 1) {
          setFirstData(i)
        }
        if (i?.order === 2) {
          setSecondData(i)
        }
      })
    }
  }, data)

  useEffect(() => {
    if (quantityCurrent && isShow) setQuantity(quantityCurrent)
  }, [quantityCurrent, isShow])

  useEffect(() => {
    if(!quantity) setQuantity(1)
  }, [quantity])

  return (
    <Dialog header={capitalizedFirst(t("modal.edit-product"))} visible={isShow} onHide={() => handleClose()}>
      <div className="flex max-w-[640px] w-[90vw] md:w-[640px] flex-col px-4 pb-4">
        {!isOutOfStock ? (
          <div className="flex flex-col">
            {firstData && (
              <div>
                <span className="text-14 font-semibold leading-20 text-gray-700">
                  {firstData.name}
                </span>
                <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-y-2">
                  <StockView
                    isImageView
                    data={firstData?.stock_variations || []}
                    selectedId={variationFirst}
                    updateSelected={(newValue: string) =>
                      handleUpdateVariationFist(newValue)
                    }
                  />
                </div>
              </div>
            )}
            {secondData && (
              <div className="mt-3">
                <span className="text-14 font-semibold leading-20 text-gray-700">
                  {secondData?.name}
                </span>
                <div className="mt-2 grid  grid-cols-1 md:grid-cols-2 gap-y-2">
                  <StockView
                    isImageView={false}
                    data={secondData?.stock_variations || []}
                    selectedId={variationSecond}
                    updateSelected={(newValue: string) =>
                      handleUpdateVariationSecond(newValue)
                    }
                  />
                </div>
              </div>
            )}

            <div className="mt-20px flex flex-col">
              <div className="text-14 font-semibold leading-20 text-gray-700">
                {capitalizedFirst(t("refund.quantity"))}
              </div>
              <div className="mt-20px flex items-center">
                <div className="flex">
                  <div className="flex w-[100px] max-w-[120px] items-center justify-between">
                    <InputNumberCustom
                      value={
                        quantity > getStockQuantity()
                          ? getStockQuantity()
                          : quantity
                      }
                      updateValue={(newValue) => setQuantity(newValue)}
                      min={1}
                      max={ticketData.isTicket && ticketData.maxLimit > 0 ? ticketData.maxLimit : getStockQuantity()}
                    />
                  </div>
                </div>
                <span className="ml-2 text-12 font-medium leading-18 text-gray-600">
                  {capitalizedFirst(
                    t("checkoutPage.stockValue", {
                      stock: getStockQuantity(),
                    })
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <span className="text-12 font-semibold leading-18 text-red-500">
              {capitalizedFirst(t("global.out-stock"))}
            </span>
          </div>
        )}
        <div className="mt-5 flex justify-end text-[16px] font-semibold leading-[24px]">
          <button
            onClick={() => handleClose()}
            className="rounded-3 border border-gray-300 py-[10px] px-[18px] text-gray-700"
          >
            {capitalizedFirst(t("global.cancel"))}
          </button>
          <button
            onClick={handleSave}
            className="ml-[12px] rounded-3 bg-blue-500 py-[10px] px-[18px] text-white hover:bg-blue-600"
          >
            {capitalizedFirst(t("global.confirm"))}
          </button>
        </div>
      </div>
    </Dialog>
  )
}
