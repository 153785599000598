import React, { useEffect, useState } from "react"
import { InputNumber } from "primereact/inputnumber"
import "./index.scss"
import { InputNumberCartType, PRODUCT_TYPE } from "./types"
import { useDebounce } from "use-debounce"
import { ConfirmModal } from "../modal"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"

export default function InputNumberCart(props: InputNumberCartType) {
  const {
    value,
    updateValue,
    max,
    confirmDelete,
    typeMax,
    typeChange,
    type,
    confirmChangeType,
    stockId,
  } = props
  const [inputValue, setInputValue] = useState<number>(0)
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const [inputDebounce] = useDebounce(inputValue, 500)
  const [currentProductId, setCurrentProductId] = useState<string>("")
  const [isShowChangeProductType, setIsShowChangeProductType] =
    useState<boolean>(false)
  const [isInitValue, setIsInitValue] = useState<boolean>(false)
  const { t } = useTranslation()

  useEffect(() => {
    setInputValue(value)
    if(!isInitValue) setIsInitValue(true)
  }, [])

  useEffect(() => {
    if (isLoad && value !== inputValue) {
      const result = inputDebounce > max ? max : inputDebounce
      updateValue(result)
    }
  }, [inputDebounce])

  useEffect(() => {
    setIsLoad(true)
    if (!currentProductId) setCurrentProductId(stockId)
  }, [])

  useEffect(() => {
    if (!isLoad) return
    if (inputValue > max) {
      setInputValue(max)
      return
    }
    if (currentProductId && currentProductId !== stockId) {
      setCurrentProductId(stockId)
      return
    }
    if (typeMax && inputValue > typeMax && type !== PRODUCT_TYPE.ORIGIN) {
      setIsShowChangeProductType(true)
      return
    }
    if (typeMax && inputValue <= typeMax && type === PRODUCT_TYPE.ORIGIN) {
      setIsShowChangeProductType(true)
      return
    }
  }, [inputValue, isLoad])

  const handleOriginValueChange = (newValue: number, target: Element) => {
    if (inputValue === newValue && newValue === 1 && !target.className.includes("pi-plus")) {
      if (confirmDelete) confirmDelete()
    }
  }

  const handleConfirm = () => {
    const newType =
      type === PRODUCT_TYPE.ORIGIN ? typeChange : PRODUCT_TYPE.ORIGIN
    confirmChangeType(newType)
    setIsShowChangeProductType(false)
  }
  const handleCancel = () => {
    if (typeMax && inputValue > typeMax) {
      setInputValue(typeMax)
    } else {
      setInputValue(inputValue + 1)
    }
    setIsShowChangeProductType(false)
  }

  useEffect(() => {
    if(!inputValue && isInitValue) {
      setInputValue(1)
    }
  }, [inputValue, isInitValue])
  
  if (!isLoad) return null
  return (
    <div className="input-number-custom rounded-3 border border-gray-200">
      <InputNumber
        min={1}
        max={max}
        value={inputValue}
        onValueChange={(e) => handleOriginValueChange(e.value as number, e.originalEvent?.target as Element)}
        onChange={(e) => setInputValue(e.value as number)}
        showButtons
        buttonLayout="horizontal"
        inputClassName="text-gray-700 123412"
        decrementButtonClassName="text-[14px] text-gray-700 px-[2px]"
        incrementButtonClassName="text-[14px] text-gray-700"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
      />
      {isShowChangeProductType && (
        <ConfirmModal
          isShow={isShowChangeProductType}
          title={capitalizedFirst(t("modal.changeConfirm"))}
          message={capitalizedFirst(t("modal.confirmChangeProductMessage"))}
          confirmLabel={"global.change"}
          handleClose={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}
    </div>
  )
}
