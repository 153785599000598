import React, { useEffect, useState } from "react"
import { getPopupBanner } from "src/services/banners-service"
import ImgCustom from "../img-custom"
import closeIcon from "src/assets/images/close-icon.svg"
import { Galleria } from "primereact/galleria"
import "./index.scss"

const EXPIRED_BANNER_TIME = 1000 * 60 * 60 // 1H

export interface BannerType {
  img: string
  url: string
}

const PopupBanner = () => {
  const [isShow, setIsShow] = useState<boolean>(true)
  const [isDetect, setIsDetect] = useState<boolean>(false)
  const [banners, setBanners] = useState<BannerType[]>([])
  const [timeSetting, setTimeSetting] = useState<number>(0)
  const getPopupBannerView = () => {
    getPopupBanner()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        const data = [] as BannerType[]
        let timeCompare = 0
        if (res?.data?.data?.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {            
            if (i?.run_status === "ON_GOING") {
              if(i?.idle_time && i?.idle_time > timeCompare) timeCompare = i?.idle_time
              data.push({
                img:
                  i?.medias?.length && i?.medias[0]?.id ? i.medias[0].id : "",
                url: i?.url || "",
              })
            }
          })
        }
        if (data.length) {
          setBanners(data)
          setTimeSetting(timeCompare)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsDetect(true))
  }
  useEffect(() => {
    const bannerTime = localStorage.getItem("bannerIdleTime")
    if (bannerTime && parseInt(bannerTime) > new Date().getTime()) {
      setIsDetect(true)
      setIsShow(false)
    } else {
      getPopupBannerView()
    }
  }, [])

  const closeBanner = () => {
    const time = new Date().getTime() + (timeSetting ? timeSetting : EXPIRED_BANNER_TIME)
    localStorage.setItem("bannerIdleTime", time + "")
    setIsShow(false)
  }

  const handleClick = (url: string) => {
    closeBanner()
    if (url) window.open(url, "_self")
  }

  if (!isDetect || !isShow || !banners.length) return null
  return (
    <div className="fixed left-[0px] top-[0px] z-[99] h-screen w-screen">
      <div className="z-[100] flex h-full w-full items-center justify-center bg-[#6b728080]">
        <div className="relative h-[300px] w-[300px] cursor-pointer md:h-[464px] md:w-[464px]">
          <div id="popup-banner" className="h-full w-full">
            <Galleria
              value={banners}
              showThumbnails={false}
              autoPlay
              circular
              transitionInterval={3000}
              item={(item: BannerType) => {
                return (
                  <div className="h-[300px] w-[300px] md:h-[464px] md:w-[464px]" onClick={() => handleClick(item.url)}>
                    <ImgCustom
                      className="h-full w-full rounded-3"
                      url={item?.img}
                      alt={item.url}
                    />
                  </div>
                )
              }}
            />
          </div>
          <div
            onClick={closeBanner}
            className=" absolute top-[-10px] right-[-10px] z-[2] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white"
          >
            <img
              alt="close-icon"
              className="h-[24px] w-[24px]"
              src={closeIcon}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PopupBanner
