import React from "react"
import ImgCustom from "src/components/img-custom"
import liveStreamsStarIcon from "src/assets/images/live-streams-star.svg"
import liveStreamsAddCartIcon from "src/assets/images/live-stream-add-cart.svg"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { DealProductModalType } from "../types"
import { RootState } from "src/app/store"
import { useDispatch, useSelector } from "react-redux"
import { useToast } from "src/hooks/use-toast"
import { CartControllerApi } from "@soctrip/axios-cart-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { setBuyNowId } from "src/features/shopping-cart"
import { convertCurrency } from "src/utils/convert-currency"
import { mathRoundNumber } from "src/utils/common"

const DealProductModal = (props: DealProductModalType) => {
  const {
    productImg,
    productName,
    price,
    originPrice,
    point,
    sold,
    shopId,
    productId,
    liveToken,
    stockId,
    isShopOwner,
    liveQuantity,
  } = props
  const { t } = useTranslation()
  const authStore = useSelector((state: RootState) => state.auth)
  const dispatch = useDispatch()
  const showToast = useToast()

  const handleBuyNow = (isBuyNow = false) => {
    if (authStore?.value) {
      const reqData = {
        object_type: "PRODUCT",
        entity_id: shopId || "",
        object_id: productId || "",
        stock_id: stockId || "",
        quantity: 1,
        token: liveToken || "",
      }
      new CartControllerApi(configHeader(SERVICE.CART))
        .cartsPost(reqData)
        .then((res) => {
          if (res?.data?.success) {
            if (isBuyNow) {
              const url = `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/shopping/order-cart`
              // const url = "http://localhost:4200/shopping/order-cart"
              dispatch(setBuyNowId(stockId || ""))
              window.open(url, "_blank")
            }
            showToast({
              detail: capitalizedFirst(
                t("product-details.add-to-cart-success-message")
              ),
              severity: "success",
            })
          }
        })
        .catch((e) => {
          const errorRes = e?.response?.data?.error
          const ERR_MSG_MAP = {
            OUT_OF_STOCK: "The quantity in item has exceeded the quantity in stock",
          }
          if (errorRes && errorRes.code === 400 && errorRes.message === ERR_MSG_MAP.OUT_OF_STOCK) {
            showToast({
              detail: capitalizedFirst(
                t("product-details.product-sold-out")
              ),
              severity: "error",
            })
            return
          }
          showToast({
            detail: capitalizedFirst(
              t("product-details.add-to-cart-failed-message")
            ),
            severity: "error",
          })
        })
    } else {
      showToast({
        detail: capitalizedFirst(t("live-stream.request-login-message")),
        severity: "warn",
      })
    }
  }

  return (
    <div className="flex flex-col rounded-3 border border-gray-200 bg-white p-3">
      <div className="h-[169px] w-[169px] rounded-2">
        <ImgCustom
          alt="dealProduct"
          url={productImg}
          className="h-full w-full rounded-2"
        />
      </div>
      <span className="mt-1 min-h-[40px] text-[14px] font-medium leading-[20px] text-gray-700 line-clamp-2">
        {productName}
      </span>
      <div className="mt-4px flex items-center text-[12px] leading-[16px] text-gray-500">
        <span>{point}</span>
        <img
          className="h-[16px] w-[16px]"
          src={liveStreamsStarIcon}
          alt="liveStreamsStarIcon"
        />
        <span className="mx-4px">{"•"}</span>
        <span>{capitalizedFirst(t("live-stream.sold", { value: sold }))}</span>
      </div>
      <div className="mt-4px flex items-center gap-[6px]">
        <span className="max-w-[50%] text-[14px] font-semibold leading-[16px] text-orange-dark-600 line-clamp-1">
          {convertCurrency(price)}
        </span>
        {mathRoundNumber(price) !== mathRoundNumber(originPrice) ? (
          <span className="max-w-[50%] text-[10px] font-medium leading-[14px] text-gray-400 line-through line-clamp-1">
            {convertCurrency(originPrice)}
          </span>
        ) : null}
      </div>
      {!isShopOwner ? (
        <div className="mt-1 flex items-center justify-end gap-[6px] ">
          {liveQuantity - sold > 0 ? (
            <>
              <div
                className="cursor-pointer rounded-3 border border-gray-300 p-1"
                onClick={() => handleBuyNow()}
              >
                <img
                  src={liveStreamsAddCartIcon}
                  alt="liveStreamsAddCartIcon"
                  className="h-[20px] w-[20px]"
                />
              </div>
              <button
                onClick={() => handleBuyNow(true)}
                className="rounded-3 bg-blue-600 py-1 px-3 text-[14px] font-medium leading-[20px] text-white hover:bg-blue-500"
              >
                {capitalizedFirst(t("live-stream.buy-now"))}
              </button>
            </>
          ) : (
            <>
              <span className="text-12 font-semibold leading-18 text-red-500">
                {capitalizedFirst(t("global.out-stock"))}
              </span>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default DealProductModal
