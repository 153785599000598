import React from "react"
import "./index.scss"
import { useToast } from "src/hooks/use-toast"
import { FileType, UploadImageCustomType } from "./types"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { isInValidFile } from "src/utils/common"
import { FILE_TYPES } from "src/constants/common"

export default function UploadImageCustom(props: UploadImageCustomType) {
  const { files, updateFiles } = props
  const showToast = useToast()
  const { t } = useTranslation()

  const validateIsImage = (file: File) => {
    if (!file) return false
    return FILE_TYPES.IMAGE.includes(file.type)
  }

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files)
      if (!filesArray.every(file => validateIsImage(file))) {
        showToast({
          detail: capitalizedFirst(t("purchase-history.wrong-file-format")),
          severity: "error",
        })
        return
      }
      const newImages = filesArray.slice(0, 5 - files.length)

      const previewImages = await Promise.all(
        newImages.map(async (file) => {
          if (file.size <= 5 * 1024 * 1024) {
            const previewImage = await readImageFile(file)
            return { file, previewImage }
          } else {
            showToast({
              detail: `File ${file.name} exceeds the 5MB limit and will not be included.`,
              severity: "warn",
            })
            return null
          }
        })
      )
      const result: FileType[] = []
      files.forEach((i) => {
        result.push(i)
      })
      previewImages.forEach((i) => {
        if (i) {
          result.push(i)
        }
      })
      updateFiles(result)
    }
  }

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...files]
    updatedImages.splice(index, 1)
    updateFiles(updatedImages)
  }

  const readImageFile = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result)
        }
      }
      reader.readAsDataURL(file)
    })
  }

  return (
    <div className="image-upload-container">
      <div className="image-preview-container">
        {files.map((file, index) => (
          <div key={index} className="image-preview relative ">
            <img
              src={file.previewImage}
              alt={`Preview ${index}`}
              className={`h-[100px] w-[100px] rounded-3 border border-gray-200 object-cover ${isInValidFile(file.file.type) ? "border border-red-500" : ""}`}
            />
            <button
              onClick={() => handleRemoveImage(index)}
              className="absolute top-2px right-2px flex items-center justify-center rounded-full border border-gray-500 bg-gray-50 p-2px hover:bg-red-500 hover:text-white"
            >
              <i className="sctr-icon-x-close text-12 "></i>
            </button>
          </div>
        ))}
        {files.length < 5 && (
          <label className="upload-label rounded-3 border border-gray-300">
            <input
              multiple
              type="file"
              onChange={handleImageChange}
              className="file-input"
              accept="image/*"
            />
            <div className="upload-icon flex flex-col gap-1">
              <div className="rounded-circle bg-gray-50 p-4px">
                <i className="sctr-icon-upload-cloud-01 rounded-circle border-[4px] border-gray-100 text-16"></i>
              </div>
              <span className="text-14 font-medium text-blue-600 ">
                {capitalizedFirst(t("refund.add-photo"))}
              </span>
            </div>
          </label>
        )}
      </div>
    </div>
  )
}
