import React, { useState, useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { capitalizedFirst } from "../../../utils/capitalized-first-character"
import {
  AddressBillingType,
  CartType,
  FeeShipType,
  ProductOrderType,
  ShopConfigType,
} from "../type"
import { InputTextarea } from "primereact/inputtextarea"
import { Dropdown } from "primereact/dropdown"
// import InvoicePaymentView from "./invoice-view"
import type { RootState } from "src/app/store"
import { useSelector, useDispatch } from "react-redux"
import { ShippingControllerApi } from "@soctrip/axios-shipment-service"
import { configHeader } from "src/config/interceptors"
import { IMAGE_TYPE, SERVICE } from "src/constants/common"
import { updateCart } from "src/features/shopping-cart"
import "./index.scss"
import { convertCurrency } from "src/utils/convert-currency"
import { gaPostAction, TAG_TYPE } from "src/utils/gtag-instance"
import { mathRoundNumber } from "src/utils/common"
import i18n from "src/locales"
import ImgCustom from "src/components/img-custom"

export const FEE_STATE_VIEW = {
  INIT: "INIT",
  LOADING: "LOADING",
  RENDER: "RENDER",
  NOT_AVAILABLE: "NOT_AVAILABLE",
  MISSING_ADDRESS: "MISSING_ADDRESS",
}

export default function ShopConfigView(props: ShopConfigType) {
  const { shopNote, isMyCartView, shipAddress, isHiddenShip, isShopTicket, id } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [feeShip, setFeeShip] = useState<FeeShipType[]>([])
  const [feeSelect, setFeeSelect] = useState<string>("")
  const [feeStateView, setFeeStateView] = useState<string>(FEE_STATE_VIEW.INIT)
  const shoppingCartStore = useSelector((state: RootState) => state.shoppingCart)

  const handleChangeNote = (value: string) => {
    const newCarts = [] as CartType[]
    shoppingCartStore.cartData.forEach((cart) => {
      if (cart.id === id) {
        newCarts.push({ ...cart, shopNote: value })
      } else {
        newCarts.push({ ...cart })
      }
    })
    dispatch(updateCart(newCarts))
  }

  const updateShopFeeStore = (feeData: FeeShipType | undefined) => {
    const newCarts = [] as CartType[]
    shoppingCartStore.cartData.forEach((cart) => {
      if (cart.id === id) {
        newCarts.push({ ...cart, feeShip: feeData })
      } else {
        newCarts.push({ ...cart })
      }
    })
    dispatch(updateCart(newCarts))
  }

  const handleChangeShopFeeShip = (value: string) => {
    setFeeSelect(value)
    const feeData = feeShip.find((f) => f.value === value) || undefined
    updateShopFeeStore(feeData)
  }

  const getShopFeeShipData = () => {
    const currentShop = shoppingCartStore.cartData?.find((shop) => shop?.id === id)
    if ((currentShop && shipAddress && !isShopTicket) || (currentShop && isShopTicket)) {
      const productData: ProductOrderType[] = []
      const addressTo: AddressBillingType = {
        name: !isShopTicket && shipAddress ? shipAddress.fullName : "",
        phone: !isShopTicket && shipAddress ? shipAddress.phoneNumber : "",
        street: !isShopTicket && shipAddress ? shipAddress.address : "",
        ward: !isShopTicket && shipAddress ? shipAddress.ward_id : "",
        district: !isShopTicket && shipAddress ? shipAddress.district_id : "",
        city: !isShopTicket && shipAddress ? shipAddress.city_id : "",
      }
      currentShop.objectDto.forEach((product) => {
        if (product.isSelected) {
          productData.push({
            stock_id: product.stockDetail.id,
            quantity: product.quantity,
          })
        }
      })
      const dataRequest = {
        city: currentShop.shopAddress.city || "",
        district: currentShop.shopAddress.district || "",
        name: currentShop.shopAddress.name || "",
        phone: currentShop.shopAddress.phone || "",
        street: currentShop.shopAddress.street || "",
        ward: currentShop.shopAddress.ward || "",
      }
      setFeeStateView(FEE_STATE_VIEW.LOADING)
      new ShippingControllerApi(configHeader(SERVICE.SHIPMENT))
        .shippingFeesPost({
          address_from: dataRequest,
          address_to: addressTo,
          products: productData,
        }, i18n.language )
        .then((res) => {
          if (res?.data?.data?.length) {
            const result: FeeShipType[] = []
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            res.data.data.forEach((i: any) => {
              result.push({
                name: i?.carrier_name,
                value: i?.id,
                discount: i?.total_fee || 0,
                url: i?.carrier_logo || "",
                expected: i?.expected,
                total_fee: i?.total_fee,
              })
            })
            setFeeShip(result)
            setFeeStateView(FEE_STATE_VIEW.RENDER)
          } else {
            setFeeStateView(FEE_STATE_VIEW.NOT_AVAILABLE)
          }
        })
        .catch(() => setFeeStateView(FEE_STATE_VIEW.NOT_AVAILABLE))
    } else setFeeStateView(FEE_STATE_VIEW.MISSING_ADDRESS)
  }

  useEffect(() => {
    if (!isMyCartView && !isHiddenShip) {
      setFeeSelect("")
      setFeeShip([] as FeeShipType[])
      updateShopFeeStore(undefined)
      getShopFeeShipData()
    }
  }, [shipAddress, i18n.language])

  const currentShop = useMemo(() => {
    const currentShop = shoppingCartStore.cartData?.find((shop) => shop?.id === id)
    return currentShop
  }, [shoppingCartStore])

  const optionalItem = (option: FeeShipType) => {
    if(isShopTicket) return null
    return (
      <div className="flex items-center gap-1 text-14 font-medium">
        <img className="h-[40px] w-[40px]" src={option?.url} alt="shipIcon" />
        <div className="flex w-full items-center justify-between gap-3">
          <div className="flex flex-col">
            <span className="ml-1 text-12 font-medium text-gray-700">{option.name}</span>
            <span className="ml-1 text-10 font-normal text-gray-500">{option.expected}</span>
          </div>
          <div className="flex items-center justify-end text-14 font-medium text-gray-700">
            <span>{convertCurrency(option?.total_fee || 0)}</span>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (feeShip.length && !feeSelect) {
      setFeeSelect(feeShip[0].value)
      updateShopFeeStore(feeShip[0])
    }
  }, [feeSelect, feeShip])

  const selectedPrivacyTemplate = () => {
    if (feeSelect) {
      const dataSelect = feeShip.find((i) => i.value === feeSelect)
      return (
        <div className="flex items-center gap-3 text-14 text-gray-600">
          <div>
            <ImgCustom
              url={dataSelect?.url || ""}
              alt={dataSelect?.value || ""}
              className="h-[30px] w-[30px]"
              type={IMAGE_TYPE.WEB}
              isFullLink={true}
            />
          </div>
          <div className="flex w-full items-center justify-between gap-3">
            <div className="flex flex-col">
              <span className="text-12 font-medium text-gray-700">{dataSelect?.name}</span>
              <span className="text-10 font-normal text-gray-500">{dataSelect?.expected}</span>
            </div>
            {
              !isShopTicket ? (
                <>
                  <div className="flex items-center justify-end text-14 font-medium text-gray-700">
                    <span>{convertCurrency(dataSelect?.total_fee || 0)}</span>
                  </div>
                </>
              ) : null
            }

          </div>
        </div>
      )
    }

    return null
  }

  
  if (!currentShop) return null

  useEffect(() => {
    if(feeStateView === FEE_STATE_VIEW.RENDER) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const itemData = [] as any[]
      let totalValue = 0
      shoppingCartStore.cartData.forEach(cart => {
        if(cart.id === id) {
          cart.objectDto.forEach(prod => {
            if(prod.isSelected) {
              totalValue = totalValue + mathRoundNumber(prod.stockDetail.price_after_tax * prod.quantity)
              itemData.push({
                item_id: prod.id,
                item_name: prod.title,
                price: mathRoundNumber(prod.stockDetail.price_after_tax),
                quantity: prod.quantity,
              })
            }
          })
        }
      })
      const shipSelect = feeShip.find(i => i.value ===feeSelect)
      const gaData = {
        currency: "USD",
        value: totalValue,
        shipping_tier: shipSelect?.name,
        itemData: itemData,
      }
      gaPostAction(TAG_TYPE.ADD_SHIPPING_INFO, gaData)
    }
  }, [feeStateView])

  return (
    <div className="mt-3 flex w-full flex-col">
      <div className="flex w-full flex-col justify-between gap-3 md:flex-row">
        <div className="flex flex-1 flex-col">
          <div className="flex justify-start ">
            <span className="truncate text-14 font-medium leading-5 text-gray-700">
              {capitalizedFirst(t("shoppingPage.cart-note"))}
            </span>
          </div>
          <InputTextarea
            autoResize
            rows={1}
            value={shopNote}
            onChange={(e) => handleChangeNote(e.target.value)}
            className={"rounded-3"}
            placeholder={capitalizedFirst(t("shoppingPage.cart-note-placeholder"))}
          />
        </div>
        {!isMyCartView && !isHiddenShip && feeStateView !== FEE_STATE_VIEW.INIT ? (
          <div className="flex h-full w-full flex-col justify-end md:w-[300px] md:justify-start">
            <div className="flex h-full justify-start ">
              <span className="truncate text-14 font-medium leading-5 text-gray-700">
                {capitalizedFirst(t("shoppingPage.shipping-option"))}
              </span>
            </div>
            {feeStateView === FEE_STATE_VIEW.LOADING ? (
              <div className="flex h-[48px] items-center justify-center rounded-3 border border-gray-200">
                <span>{capitalizedFirst(t("global.loading"))}</span>
              </div>
            ) : null}
            {feeStateView === FEE_STATE_VIEW.NOT_AVAILABLE ? (
              <div className="flex h-[48px] w-full items-center justify-center rounded-3 border border-gray-300">
                <span className="text-14 font-normal text-gray-500">{capitalizedFirst(t("shoppingPage.ship-valid"))}</span>
              </div>
            ) : null}
            {currentShop && feeStateView === FEE_STATE_VIEW.MISSING_ADDRESS ? (
              <div className="flex flex-col gap-1">
                <div className="flex h-[48px] w-full items-center justify-center rounded-3 border border-gray-300">
                  <span className="text-14 font-normal text-gray-500">
                    {capitalizedFirst(t("shoppingPage.ship-valid"))}
                  </span>
                </div>
                <span className="text-[10px] leading-[14px] text-red-500 ">
                  {capitalizedFirst(t("shoppingPage.missing-address"))}
                </span>
              </div>
            ) : null}
            {feeStateView === FEE_STATE_VIEW.RENDER ? (
              <div className="w-full">
                <Dropdown
                  value={feeSelect}
                  valueTemplate={selectedPrivacyTemplate}
                  onChange={(e) => handleChangeShopFeeShip(e.value)}
                  options={feeShip}
                  optionLabel="name"
                  id="feeShipDropdown"
                  className={`flex h-[48px] w-full items-center rounded-3 ${
                    feeShip ? "bg-blue-50" : ""
                  }`}
                  itemTemplate={optionalItem}
                  disabled={isShopTicket}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  )
}
